import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import React from "react"

import { i18nLocale } from "@trueskin-web/locales"
import { authService } from "@trueskin-web/services"

const GraphQLProvider = (props) => {
  const httpLink = createHttpLink({
    uri: process.env.GATSBY_GQL_URL,
  })

  const headersLink = setContext((_, { headers }) => {
    const withAuthorization = authService.getJwt()
      ? { Authorization: `Bearer ${authService.getJwt()}` }
      : {}

    return {
      headers: {
        ...headers,
        ...withAuthorization,
        "x-fs-locale": i18nLocale(),
      },
    }
  })

  const client = new ApolloClient({
    link: headersLink.concat(httpLink),
    cache: new InMemoryCache(),
  })

  return <ApolloProvider client={client} {...props} />
}

export { GraphQLProvider }
