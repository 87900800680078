import { userClient } from "@trueskin-web/apis"
import { parseCookie } from "@trueskin-web/cookies"

import * as authService from "./auth"
import * as storageService from "./storage"

const storageKeys = storageService.getStorageKeys()

const getItem = (id) => storageService.getItem({ id })

const isEnabled = (id) => !!getItem(id)

const cleanupStorage = () =>
  Object.keys(storageKeys)
    .filter((storageKey) => storageKey.startsWith("VWO_"))
    .forEach((storageKey) =>
      storageService.removeItem({
        id: storageKeys[storageKey],
      })
    )

const getInitialQuestionnaireId = () =>
  getItem(storageKeys.VWO_INITIAL_QUESTIONNARE_ID)

const getTofuInitialQuestionnaireId = () =>
  getItem(storageKeys.VWO_TOFU_INITIAL_QUESTIONNARE_ID)

const getInitialQuestionnaireProvider = () =>
  getItem(storageKeys.VWO_INITIAL_QUESTIONNARE_PROVIDER)

const getQuestionnaireProvider = () =>
  getItem(storageKeys.VWO_QUESTIONNARE_PROVIDER)

const isSideEffectsSelfServiceEnabled = () =>
  isEnabled(storageKeys.VWO_SIDE_EFFECTS_SELF_SERVICE)

const getTestPresetType = () => getItem(storageKeys.VWO_TEST_PRESET_TYPE)

const isPlanSelectionTestReleaseEnabled = () =>
  getItem(storageKeys.VWO_PLAN_SELECTION_TEST_RELEASE)

const isClosedClaEnabled = () => isEnabled(storageKeys.VWO_CLOSED_CLA)

const getClosedClaVariant = () => getItem(storageKeys.VWO_CLOSED_CLA)

const isFormulaExplorationEnabled = () =>
  isEnabled(storageKeys.VWO_FORMULA_EXPLORATION)

const removeStorageValues = () =>
  Object.keys(window.localStorage)
    .filter((storageKey) => storageKey.startsWith(storageKeyPrefix))
    .forEach((storageKey) => storageService.removeItem(storageKey))

const getExperimentVariationName = (variationId) =>
  +variationId === 1 ? "Control" : `Variation ${+variationId - 1}`

const getVwoExperiments = () =>
  Object.entries(parseCookie(document.cookie))
    .filter(
      ([key, value]) =>
        key.startsWith("_vis_opt_exp_") && key.endsWith("_combi") && value
    )
    .map(([key, value]) => ({
      experimentId: +key.replace(/[^0-9]/g, ""),
      variationName: getExperimentVariationName(value),
    }))

const getVwoExperimentsEventData = () => {
  const vwoExperiments = getVwoExperiments()

  return {
    VWOExperimentID: vwoExperiments?.map((item) => item.experimentId).join(","),
    VWOExperimentVariant: vwoExperiments
      ?.map((item) => item.variationName)
      .join(","),
  }
}

function addVariationListener() {
  window.VWO = window.VWO || []
  window.VWO.push([
    "onVariationApplied",
    function (data) {
      if (!data) return
      const expId = data[1]
      const variationId = data[2]
      const isLoggedIn = authService.getJwt()
      if (
        isLoggedIn &&
        expId &&
        variationId &&
        ["VISUAL_AB", "VISUAL", "SPLIT_URL"].includes(
          window._vwo_exp[expId].type
        )
      ) {
        const variationName = getExperimentVariationName(variationId)
        userClient.saveVwoExperiments([{ experimentId: +expId, variationName }])
      }
    },
  ])
}

if (typeof window !== "undefined") {
  addVariationListener()
}

export {
  cleanupStorage,
  getInitialQuestionnaireId,
  getInitialQuestionnaireProvider,
  getQuestionnaireProvider,
  getTestPresetType,
  isPlanSelectionTestReleaseEnabled,
  isSideEffectsSelfServiceEnabled,
  isClosedClaEnabled,
  getClosedClaVariant,
  isFormulaExplorationEnabled,
  removeStorageValues,
  getVwoExperimentsEventData,
  getVwoExperiments,
  getTofuInitialQuestionnaireId,
}
