import React from "react"
import { QueryClient, QueryClientProvider } from "react-query"

const ApiProvider = (props) => {
  const client = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 0,
      },
    },
  })

  return <QueryClientProvider client={client} {...props} />
}

export { ApiProvider }
