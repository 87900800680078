import * as vwoService from "./vwo"

const supportedTreatmentIndications = ["Akne", "Slow-Aging"]

const isClosedClaEnabled = ({ treatmentPlan, hasTwoMonthsPackage } = {}) => {
  if (!vwoService.isClosedClaEnabled()) {
    return false
  }

  if (treatmentPlan) {
    return false
  }

  if (!hasTwoMonthsPackage) {
    return false
  }

  return true
}

const isFormulaExplorationEnabled = ({ treatmentPlan } = {}) => {
  if (!vwoService.isFormulaExplorationEnabled()) {
    return false
  }

  if (
    !supportedTreatmentIndications.some(
      (supportedTreatmentIndication) =>
        treatmentPlan?.indication?.name === supportedTreatmentIndication
    )
  ) {
    return false
  }

  return true
}

export { isClosedClaEnabled, isFormulaExplorationEnabled }
