import { apiV1, apiV2 } from "./api"
import * as marketingClient from "./marketing"

function cancelSubscription({ subscriptionId, alsoRefund = false } = {}) {
  return apiV1(
    `users/me/subscriptions/${subscriptionId}/cancel`,
    {
      method: "POST",
      body: {
        alsoRefund,
      },
    },
    3
  )
}

function requestSubscriptionCancellation(subscriptionId) {
  return apiV1(
    `users/me/subscriptions/${subscriptionId}/request-cancellation`,
    {
      method: "POST",
    }
  )
}

function pauseSubscription({
  subscriptionId,
  pauseDate,
  resumeDate,
  pauseReason,
}) {
  const pause_date = new Date(pauseDate)
  const resume_date = new Date(resumeDate)
  resume_date.setHours(pause_date.getHours())
  resume_date.setMinutes(pause_date.getMinutes())
  resume_date.setSeconds(pause_date.getSeconds())

  return apiV1(`users/me/subscriptions/${subscriptionId}/pause`, {
    body: {
      pauseOptions: {
        pause_option: "end_of_term",
        pause_date,
        resume_date,
      },
      pauseReason,
    },
  })
}

function resumeSubscription(subscriptionId) {
  return apiV1(`users/me/subscriptions/${subscriptionId}/resume`, {
    body: {
      resumeOptions: {
        resume_option: "immediately",
      },
    },
  })
}

function logSubscriptionError(error) {
  return apiV1("users/me/subscriptions/error", {
    body: {
      error: error.message,
    },
  })
}

function infoVoucher(voucherCode) {
  return apiV2(`customers/codes/${voucherCode}`).then((r) => r.data)
}

function applyVoucher(voucherCode) {
  return apiV1(`vouchers/${voucherCode}/apply`, {
    method: "POST",
  })
}

function removeVoucher() {
  return apiV1(`vouchers/remove`, {
    method: "POST",
  })
}

function purchaseOrder(
  campaignTracking,
  marketingEventData = null,
  v2PaymentMethodDetails = null
) {
  marketingClient.sendEvent({
    name: marketingClient.EVENT_TYPES_ENUM.PAYMENT_INITIATED,
    data: marketingEventData,
  })

  return apiV1("users/me/order/purchase", {
    body: {
      campaignTracking,
      ...v2PaymentMethodDetails,
    },
  }).catch((e) => {
    marketingClient.sendEvent({
      name: marketingClient.EVENT_TYPES_ENUM.PAYMENT_ERROR,
      data: marketingEventData,
    })
    throw e
  })
}

function applyCancellationDiscount() {
  return apiV1(`chargebee/apply-cancellation-discount`, { method: "POST" })
}

function removeScheduledCancellation() {
  return apiV1(`chargebee/remove-scheduled-cancellation`, { method: "POST" })
}

function createPayment(body) {
  return apiV2(`vendors/adyen/payment/recurring`, { method: "POST", body })
}

function getPaymentMethods(body) {
  return apiV2(`vendors/adyen/payment/methods`, {
    method: "POST",
    body,
  })
}

function getPaymentDetails(body) {
  return apiV2(`vendors/adyen/payment/details`, {
    method: "POST",
    body,
  })
}

function createPaymentSession(gateway, data = {}) {
  if (data?.orderId?.startsWith("plink_")) {
    return apiV2(`payment-links/${data.orderId}/sessions/klarna`, {
      method: "POST",
    })
  }

  return apiV2(`payments/sessions/${gateway}`, { method: "POST", body: data })
}

function createBraintreePaymentSession(params) {
  const { queryKey } = params
  const [, orderId] = queryKey

  if (orderId?.startsWith("plink_")) {
    return apiV2(`payment-links/${orderId}/sessions/braintree`, {
      method: "POST",
    }).then((response) => response.data)
  }

  return createPaymentSession("braintree").then((response) => response.data)
}

function getPrimaryPaymentMethod() {
  return apiV2("payment-methods/primary")
}

function tokenizePaymentMethod(data) {
  if (data.orderId?.startsWith("plink_")) {
    return apiV2(`payment-links/${data.orderId}/tokenize`, {
      method: "POST",
      body: data,
    })
  }

  return apiV2("payment-methods/tokenize", { method: "POST", body: data })
}

export {
  cancelSubscription,
  requestSubscriptionCancellation,
  pauseSubscription,
  resumeSubscription,
  logSubscriptionError,
  infoVoucher,
  applyVoucher,
  removeVoucher,
  purchaseOrder,
  applyCancellationDiscount,
  removeScheduledCancellation,
  createPayment,
  getPaymentMethods,
  getPaymentDetails,
  getPrimaryPaymentMethod,
  tokenizePaymentMethod,
  createPaymentSession,
  createBraintreePaymentSession,
}
