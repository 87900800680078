import { apiV1, apiV2 } from "./api"

const UserRatingTypes = {
  SELF_SERVICE_PAGE: "SELF_SERVICE_PAGE",
  TREATMENT_PLAN: "TREATMENT_PLAN",
}

function saveVwoExperiments(data) {
  return apiV1("users/me/vwo-experiments", {
    body: data,
  })
}

function update(updates) {
  return apiV1("users/me", {
    method: "PUT",
    body: updates,
  })
}

function setCountry(countryCode) {
  return update({ country: countryCode })
}

function uploadImages({ consultationId, files }) {
  const formData = new FormData()

  formData.append("front", files[0])
  formData.append("right", files[1])
  formData.append("left", files[2])

  return apiV1(
    `users/me/consultations/${consultationId}/images`,
    {
      body: formData,
    },
    2
  )
}

function uploadImage({
  consultationId,
  consultationMessageId,
  file,
  orientation,
  totalImagesToUpload,
}) {
  const formData = new FormData()

  formData.append("file", file)
  formData.append("totalImagesToUpload", totalImagesToUpload)

  const url = consultationMessageId
    ? `users/me/consultations/${consultationId}/messages/${consultationMessageId}/images/orientations/${orientation}`
    : `users/me/consultations/${consultationId}/images/orientations/${orientation}`

  return apiV1(
    url,
    {
      body: formData,
    },
    2
  )
}

function tempUploadImages({ tempId, files }) {
  const formData = new FormData()

  formData.append("front", files[0])
  formData.append("right", files[1])
  formData.append("left", files[2])

  return apiV1(
    `users/me/consultations/images/${tempId}`,
    {
      body: formData,
    },
    2
  )
}

function tempUploadImage({ tempId, file, orientation }) {
  const formData = new FormData()

  formData.append("file", file)

  return apiV1(
    `users/me/consultations/images/${tempId}/orientations/${orientation}`,
    {
      body: formData,
    },
    2
  )
}

function skipImageUpload(consultationId) {
  return apiV1(`users/me/consultations/${consultationId}/images/skip`, {
    method: "POST",
  })
}

function tempSkipImageUpload(tempId) {
  return apiV1(`users/me/consultations/images/${tempId}/skip`, {
    method: "POST",
  })
}

function getReferralDetails() {
  return apiV2("customer/referral").then((d) => d.data)
}

function getTreatmentSummary() {
  return apiV1("users/me/treatment/summary")
}

function getTreatmentProgressOverview() {
  return apiV1("users/me/treatment/progress/overview")
}

function getTreatmentProgress(skip, take) {
  return apiV1(`users/me/treatment/progress`, {
    params: {
      skip,
      take,
    },
  })
}

function getTreatmentDiscovery() {
  return apiV1("users/me/treatment/discover")
}

function createImageUploadConsultation() {
  return apiV1("users/me/consultations/image-upload", { method: "POST" })
}

function getDataCheckDetails() {
  return apiV1("users/me/data-check")
}

function markDataCheckAsSubmitted() {
  return apiV1("users/me/data-check", { method: "POST" })
}

function changeRenewalDate({ subscriptionId, renewalDate, renewalReason }) {
  return apiV1(`users/me/subscriptions/${subscriptionId}/updateRenewal`, {
    body: {
      renewalDate,
      renewalReason,
    },
  })
}

function getSubscriptionHistory() {
  return apiV1("users/me/subscriptions/history")
}

/**
 * @deprecated This doesn't seem to be used anywhere. Double check and remove
 */
function getSubscriptionDetails() {
  return apiV1("users/me/subscription/details")
}

function getSubscriptionSummary({
  queryKey: [_, subscriptionId, renewalDate],
}) {
  let url = `users/me/subscriptions/${subscriptionId}/summary`

  if (renewalDate) {
    url += `?renewal-date=${renewalDate}`
  }

  return apiV1(encodeURI(url))
}

function getMyRoutineV2() {
  return apiV1("users/me/routine-v2")
}

function getMyReward() {
  return apiV1("users/me/referral-rewards")
}

function useMyReward(rewardId, fromUserId) {
  return apiV1(`users/me/referral-rewards`, {
    body: {
      rewardId,
      fromUserId,
    },
  })
}

function submitTelemedicineAgreement({ telemedicine }) {
  return apiV1(`users/me/agreements`, {
    body: { telemedicine },
  })
}

function getReviewLink({ customer, email, platform }) {
  return apiV1("users/me/review-link", {
    // hotfix: forcing trustedshops because trustpilot got disabled
    body: { customer, email, platform: "trustedshops" },
  })
}

function getHealthRecord() {
  return apiV1("users/me/health-record")
}

function getMedicalData(userId) {
  return apiV1(`users/${userId}/health-record/medical-data`)
}

function updateMedicalData(values) {
  return apiV1("health-record/medical-data/update", {
    body: values,
  })
}

function updateMedicalDataConsent() {
  return apiV1("health-record/medical-data/consent", {
    method: "POST",
  })
}

function createUserRating({ type, id, rating, feedback }) {
  return apiV1("user-ratings", {
    body: {
      ratedEntityType: type,
      ratedEntityId: id,
      rating,
      feedback,
    },
  })
}

function findUserRating({ type, id }) {
  return apiV1("user-ratings/find", {
    params: {
      ratedEntityType: type,
      ratedEntityId: id,
    },
  })
}

export {
  update,
  setCountry,
  uploadImages,
  uploadImage,
  tempUploadImages,
  tempUploadImage,
  skipImageUpload,
  tempSkipImageUpload,
  getReferralDetails,
  getTreatmentSummary,
  getTreatmentProgressOverview,
  getTreatmentProgress,
  getTreatmentDiscovery,
  createImageUploadConsultation,
  getDataCheckDetails,
  markDataCheckAsSubmitted,
  changeRenewalDate,
  getSubscriptionHistory,
  getMyRoutineV2,
  getMyReward,
  useMyReward,
  submitTelemedicineAgreement,
  getSubscriptionDetails,
  saveVwoExperiments,
  getSubscriptionSummary,
  getReviewLink,
  getHealthRecord,
  getMedicalData,
  updateMedicalData,
  updateMedicalDataConsent,
  UserRatingTypes,
  createUserRating,
  findUserRating,
}
