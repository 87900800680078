import { removeCookie, setCookie } from "@trueskin-web/cookies"

import * as storageService from "./storage"

const storageKeys = storageService.getStorageKeys()
const storageKeyJwt = storageKeys.AUTH_JWT
const storageKeyFirstname = storageKeys.FIRSTNAME
const storageKeyUserCreatedAt = storageKeys.USER_CREATED_AT
const storageKeyTempToken = storageKeys.TEMP_TOKEN

const getJwt = () => storageService.getItem({ id: storageKeyJwt })

const getFirstname = () => storageService.getItem({ id: storageKeyFirstname })

const getTempToken = () => storageService.getItem({ id: storageKeyTempToken })

const saveJwt = (jwt) => {
  storageService.setItem({ id: storageKeyJwt, data: jwt })
}

const saveFirstname = (firstname) => {
  storageService.setItem({ id: storageKeyFirstname, data: firstname })
}

const saveTempToken = (temptoken) => {
  storageService.setItem({ id: storageKeyTempToken, data: temptoken })
}

const removeJwt = () => {
  storageService.removeItem({ id: storageKeyJwt })
}

const removeFirstname = () => {
  storageService.removeItem({ id: storageKeyFirstname })
}

const removeTempToken = () => {
  storageService.removeItem({ id: storageKeyTempToken })
}

const saveUserCreationTimestamp = (utcDate) => {
  if (!utcDate) {
    return
  }

  const timestamp = new Date(utcDate).getTime()

  setCookie(storageKeyUserCreatedAt, timestamp)
}

const removeUserCreationTimestamp = () => {
  removeCookie(storageKeyUserCreatedAt)
}

const saveUserTreatmentDetails = (user) => {
  const { indication } = user.treatmentPlan || {}
  const ingredients = user.activeIngredients.join(", ")
  const vwoFormulaSegments = user.vwoFormulaSegments.join(", ")
  const storageKeys = storageService.getStorageKeys()
  setCookie(storageKeys.VWO_TREATMENT_INDICATION, indication?.name)
  setCookie(storageKeys.VWO_TREATMENT_INGREDIENTS, ingredients)
  setCookie(storageKeys.VWO_FORMULA_SEGMENTS, vwoFormulaSegments)
}

const saveUserSubscriptionType = (user) => {
  const { type, paymentOrder = {} } = user.subscription || {}
  const subscriptionType =
    type === "recurring" && paymentOrder.instalments?.count
      ? `recurring_${paymentOrder.instalments.count}m`
      : type === "single"
      ? "single"
      : type === "receipt"
      ? "prescription"
      : ""
  setCookie(storageKeys.VWO_SUBSCRIPTION_TYPE, subscriptionType)
}

// TODO: proprietary app related local storage keys should be prefixed to promote automatic cleanup
const cleanupStorage = () => {
  removeJwt()
  removeFirstname()
  removeUserCreationTimestamp()
}

export {
  getJwt,
  getFirstname,
  getTempToken,
  saveJwt,
  saveFirstname,
  saveTempToken,
  removeJwt,
  removeTempToken,
  removeFirstname,
  saveUserCreationTimestamp,
  cleanupStorage,
  saveUserTreatmentDetails,
  saveUserSubscriptionType,
}
