import "url-search-params-polyfill"

import { amplitudeClient } from "@trueskin-web/apis"
import { getCookie, setCookie } from "@trueskin-web/cookies"
import { trackingService } from "@trueskin-web/services"

function storeTikTokIfPresent(searchParams) {
  if (searchParams.has("ttclid")) {
    // https://ads.tiktok.com/marketing_api/docs?id=1701890979375106 The ttclid is valid for 7 days.
    setCookie("_ttclid", searchParams.get("ttclid"), 7)
  }
}

export function getTikTokClid() {
  return getCookie("_ttclid")
}

const LAST_TOUCH_POINT_INDEX = 3

let trackedOnThisSPALoad = false
export const trackTouchPoint = (location) => {
  if (trackedOnThisSPALoad) {
    return false
  }
  trackedOnThisSPALoad = true
  const { search: searchParamsString, pathname } = location
  const searchParams = new URLSearchParams(searchParamsString)
  const params = Array.from(searchParams.entries()).reduce(
    (acc, [key, value]) => {
      acc[key] = value
      return acc
    },
    {}
  )

  const allTouchPoints = trackingService.getAllTrackingTouchPoints()
  let minutesSinceLastTouchPoint = null
  if (allTouchPoints.length) {
    const lastTouchPoint = allTouchPoints[allTouchPoints.length - 1]
    const lastTouchPointDate = new Date(lastTouchPoint.date)
    const difference = new Date().getTime() - lastTouchPointDate.getTime()
    minutesSinceLastTouchPoint = Math.ceil(difference / (1000 * 60))
  }
  const referrer = document?.referrer || ""
  const hasReferrer =
    referrer.length &&
    ["formelskin.de", "typeform.com", window?.location?.hostname].every(
      (ref) => !referrer.includes(ref)
    )
  const hasTrackingParams = Object.keys(params).some((param) =>
    [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_content",
      "utm_term",
      "utm_keyword",
      "matchtype",
      "fbclid",
      "gclid",
    ].includes(param)
  )
  const touchPoint = {
    params,
    referrer,
    pathname,
    minutesSinceLastTouchPoint,
  }
  if (
    minutesSinceLastTouchPoint === null ||
    minutesSinceLastTouchPoint >= 60 ||
    hasReferrer ||
    hasTrackingParams
  ) {
    trackingService.addNewTouchPoint(touchPoint)
  }
}
export const trackCampaignIfPresent = (location) => {
  trackTouchPoint(location)
  const { search: searchParamsString, pathname: target } = location

  if (!searchParamsString) {
    return
  }

  const searchParams = new URLSearchParams(searchParamsString)

  storeTikTokIfPresent(searchParams)
  if (
    !searchParams.has("utm_source") &&
    !searchParams.has("utm_medium") &&
    !searchParams.has("utm_campaign") &&
    !searchParams.has("utm_content") &&
    !searchParams.has("utm_term") &&
    !searchParams.has("utm_keyword") &&
    !searchParams.has("matchtype") &&
    !searchParams.has("fbclid") &&
    !searchParams.has("gclid")
  ) {
    return
  }

  const storedTouchpoints = trackingService.getTrackingTouchpoints()
  let touchpoints = storedTouchpoints ? storedTouchpoints : Array(4).fill(null)
  let overwriteLastTouchpoint = true
  const newTouchpoint = {
    utm_source: searchParams.get("utm_source"),
    utm_medium: searchParams.get("utm_medium"),
    utm_campaign: searchParams.get("utm_campaign"),
    utm_content: searchParams.get("utm_content"),
    utm_term: searchParams.get("utm_term"),
    utm_keyword: searchParams.get("utm_keyword"),
    matchtype: searchParams.get("matchtype"),
    referral: document?.referrer,
    target,
    fbclid: searchParams.get("fbclid"),
    gclid: searchParams.get("gclid"),
  }
  // we replace the last touch point
  // and the 1st,2nd,3rd position that matches the same as the last touch point
  if (searchParams.has("optimize_redirect")) {
    const lastTP = JSON.stringify(touchpoints[LAST_TOUCH_POINT_INDEX])
    touchpoints = touchpoints.map((tp) => {
      if (JSON.stringify(tp) === lastTP) {
        return null
      }
      return tp
    })
  }

  for (let i = 0; i < touchpoints.length; i++) {
    if (touchpoints[i] === null) {
      touchpoints[i] = newTouchpoint
      overwriteLastTouchpoint = false
      if (i !== touchpoints.length - 1) {
        touchpoints[touchpoints.length - 1] = newTouchpoint
      }
      break
    }
  }

  if (overwriteLastTouchpoint) {
    touchpoints[touchpoints.length - 1] = newTouchpoint
  }

  trackingService.saveTrackingTouchpoints(touchpoints)
}

export const PH_EVENTS_TYPES_ENUM = {
  PAGE_VIEW: "$pageview",
  ASK_YOUR_DOCTOR: "Ask_your_doctor",
  SELECT_MEDICAL_QUESTION: "Select_medical_question",
  SELECT_OTHER_QUESTION: "Select_other_question",
  PAGE_VIEW_CONSULTATIONS_NEW_QUESTION: "Pageview_consultations_NewQuestion",
  NEW_QUESTION_UPLOAD_IMAGES: "NewQuestion_upload_images",
  NEW_QUESTION_SEND: "NewQuestion_send",
  CHECK_IN_PAGE_VIEW_QUESTIONNAIRE: "Pageview_Questionnaire",
  CHECK_IN_SEND_QUESTIONNAIRE: "Send_questionnaire",
  CHECK_IN_CANCEL_QUESTIONNAIRE: "Cancel_questionnaire",
  CHECK_IN_PAGE_VIEW_PHOTOS: "Pageview_checkin_photos",
  CHECK_IN_PHOTOS_SENT: "Checkin_photos_sent",
  CHECK_IN_PHOTOS_CANCELED: "Checkin_photos_cancelled",
  CHECK_IN_PHOTOS_SKIP: "Checkin_photos_skip",
  PROGRESS_UPLOAD_PHOTOS: "Progress_upload_photos",
  PROGRESS_PHOTOS_SAVED: "Progress_photos_saved",
  PROGRESS_SCROLL: "Progress_scroll",
  PROGRESS_LOAD_MORE: "Progress_load_more",
  PROGRESS_STRAIGHT: "Progress_straight",
  PROGRESS_RIGHT: "Progress_right",
  PROGRESS_LEFT: "Progress_left",
  MODIFY_AUTO_REFILL_DATE: "Modify_autorefill_date",
  SAVE_AUTO_REFILL_DATE: "Save_new_autorefill_date",
  CANCEL_AUTO_REFILL_DATE: "Cancell_new_autorefill_date",
  CLOSE_AUTO_REFILL_DATE: "Close_new_autorefill_date_layover",
  UPDATE_MEDICAL_DATA: "Update_medicaldata",
  SAVE_MEDICAL_DATA: "Save_medicaldata_update",
  CANCEL_MEDICAL_DATA: "Cancell_medicaldata_update",
  ALL_GOOD_MEDICAL_DATA: "Allgood_medicaldata",
  SELECT_PAYMENT_TYPE: "Select_payment_type",
  CHANGE_PAYMENT_TYPE: "Change_payment_type",
  PAGE_VIEW_MEDICAL_DATA: "Pageview_medicaldata",
  PAGE_VIEW_PROFILE: "Pageview_profile",
  PAGE_VIEW_SHIPPING: "Pageview_shipping",
  PAGE_VIEW_NOTIFICATIONS: "Pageview_notifications",
  PAGE_VIEW_HELP: "Pageview_help",
  ACCOUNT_PROFILE_COPY_REFERRAL: "Account_profile_copy_referral_code",
  ACCOUNT_PROFILE_SEND_EMAIL: "Account_profile_send_email",
  ACCOUNT_PROFILE_UPDATE_PHONE: "Account_profile_update_phone",
  ACCOUNT_PROFILE_SAVE_PHONE: "Account_profile_save_phone",
  ACCOUNT_PROFILE_UPDATE_BILLING: "Account_profile_update_billing_address",
  ACCOUNT_PROFILE_SAVE_BILLING: "Account_profile_save_billing_address",
  ACCOUNT_PROFILE_UPDATE_EMAIL: "Account_profile_update_mail_address",
  ACCOUNT_PROFILE_SAVE_EMAIL: "Account_profile_save_mail_address",
  PAGE_VIEW_HOME: "Pageview_app_home",
  PAGE_VIEW_CONSULTATIONS: "Pageview_app_consultations",
  PAGE_VIEW_PROCESS: "Pageview_app_progress",
  PAGE_VIEW_SUBSCRIPTION: "Pageview_subscription",
  PAGE_VIEW_ACCOUNT: "Pageview_app_account",
  MODIFY_AUTO_REFILL_DATE_2: "Modify_autorefill_date_2",
  DELETE_ACCOUNT_START: "Delete_account_start",
  MODIFY_MY_SET: "Modify_my_set",
  SAVE_SET_MODIFICATIONS: "Save_set_modifications",
  ADD_ADDITIONAL_PRODUCT: "Add_additional_product",
  ADD_ADDITIONAL_PRODUCT_ON_LAYOVER: "Add_additional_product_onlayover",
  CANCEL_ADDITIONAL_PRODUCT: "No_thanks_additional_product",
  CLOSE_ADDITIONAL_PRODUCT: "Close_additional_product_layover",
  ACCOUNT_SHIPPING_VIEW_LAST_ORDER: "Account_shipping_view_last_order_details",
  ACCOUNT_SHIPPING_VIEW_INVOICE: "Account_shipping_view_last_invoice",
  ACCOUNT_NOTIFICATION_TOGGLE: "Account_notifications_SMS_Toggle",
  CLA_LOGOUT: "CLA_Logout",
  HOME_SHIPPING_BTN: "Home_shipping_button",
  HOME_PLAN_BTN: "Home_plan_button",
  HOME_ROUTINE_BTN: "Home_routine_button",
  HOME_CONSULTATIONS_BTN: "Home_consultations_button",
  HOME_PROCESS_BTN: "Home_progress_button",
  HOME_INVITE_FRIENDS_BTN: "Home_invite_friends",
  PAGE_VIEW_MY_PLAN: "Pageview_app_plan",
  EXPAND_DOCTOR_NOTES: "Expand_doctornote",
  CLICK_YOUR_ROUTINE: "Your_routine",
  CLICK_WATCH_YOUTUBE: "Watch_youtube_application",
  HOME_LAYOVER_IMAGE_UPLOAD: "Home_Layover_image_upload",
  HOME_LAYOVER_IMAGE_UPLOAD_BTN: "Home_Layover_image_upload_button",
  HOME_LAYOVER_IMAGE_UPLOAD_NO_IMAGES: "Home_Layover_image_upload_no_images",
  HOME_LAYOVER_IMAGE_UPLOAD_CLOSE: "Home_layover_image_upload_closewindow",
  ACCOUNT_SUBSCRIPTION_SELECTED_PLAN: "Account_subscription_select_plan",
}

export const trackPHCustomEvent = (eventName, options = null) => {
  if (!window.posthog) return
  if (!eventName) return

  window.posthog.capture(eventName, options)
  if (window.ENABLE_DEBUG_POSTHOG) {
    console.log("Posthog event: ", eventName, options)
  }
}

export const trackCustomEvent = (eventName, options = null) => {
  const phEvent = PH_EVENTS_TYPES_ENUM[eventName]
  const analyticEvent = amplitudeClient.ANALYTIC_EVENTS[eventName]

  if (phEvent) {
    trackPHCustomEvent(phEvent, options)
  }

  if (analyticEvent) {
    amplitudeClient.trackEvent(analyticEvent, options)
  }
}

export const resetPHTracking = () => {
  if (!window.posthog) return

  window.posthog.reset()
}
