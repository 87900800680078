import * as storageService from "./storage"

const storageKeys = storageService.getStorageKeys()
const storageKey = storageKeys.BANNERS_HIDDEN

const getHiddenBanners = () => storageService.getItem({ id: storageKey }) || []

const hideBanner = (id) =>
  storageService.setItem({
    id: storageKey,
    data: [...getHiddenBanners(), { id, timestamp: new Date() }],
  })

const unhideBanner = (id) =>
  storageService.setItem({
    id: storageKey,
    data: [...getHiddenBanners().filter(({ id: bannerId }) => bannerId !== id)],
  })

export { getHiddenBanners, hideBanner, unhideBanner }
