import * as vwoService from "./vwo"

const supportedIngredient = "TRETINOIN"
const supportedTreatmentGoal = "Akne"

const isSideEffectsSelfServiceEnabled = (treatmentPlan, activeIngredients) => {
  if (!vwoService.isSideEffectsSelfServiceEnabled()) {
    return false
  }

  if (treatmentPlan?.indication?.name !== supportedTreatmentGoal) {
    return false
  }

  if (
    !activeIngredients?.some(
      (ingredient) => ingredient.toUpperCase() === supportedIngredient
    )
  ) {
    return false
  }

  return true
}

export { isSideEffectsSelfServiceEnabled }
