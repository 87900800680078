import { portalService } from ".."

import * as storageService from "./storage"

const storageKeys = storageService.getStorageKeys()
const homeIntroDismissed = storageKeys.HOME_INTRO_DISMISSED

const isIntroAvailable = ({ treatmentPlan, hasTwoMonthsPackage }) => {
  if (
    !portalService.isClosedClaEnabled({
      treatmentPlan,
      hasTwoMonthsPackage,
    })
  ) {
    return false
  }

  if (isIntroDismissed()) {
    return false
  }

  return true
}

const isIntroDismissed = () =>
  !!storageService.getItem({ id: homeIntroDismissed })

const dismissIntro = () =>
  storageService.setItem({
    id: homeIntroDismissed,
    data: true,
  })

const cleanupStorage = () => {
  storageService.removeItem({ id: homeIntroDismissed })
}

export { isIntroAvailable, isIntroDismissed, dismissIntro, cleanupStorage }
