import { useState } from "react"

import i18next, { Trans } from "@trueskin-web/translations"

const localizedErrors =
  i18next.t("Generic.errors", {
    returnObjects: true,
  }) || {}

const ERRORS_MESSAGES = {
  "Identifier or password invalid.": localizedErrors.emailOrPasswordInvalid,
  "invalid.currentPassword": localizedErrors.invalidCurrentPassword,
  "Username already taken.": localizedErrors.usernameAlreadyTaken,
  "This email does not exist.": localizedErrors.nonExistingEmail,
  "Failed to fetch": localizedErrors.failedTofetch,
  "Payment collection failed. Reason: (3001) Insufficient funds.":
    localizedErrors.paymentFailed,
  "Insufficient funds": localizedErrors.insufficientFunds,
  "email.alreadyTaken": localizedErrors.emailAlreadyTaken,
  "token.expired": localizedErrors.tokenExpired,
  "renewalDate.cannotEqualTodayOrEarlier":
    localizedErrors.renewalDateCannotEqualTodayOrEarlier,
  "renewalDate.cannotEqualSameRenewalDate":
    localizedErrors.cannotEqualSameRenewalDate,
  "Incorrect code provided.": localizedErrors.incorrectCode,
}

const CLIENT_VALIDATION_ERROR_TYPES = {
  salutation: [{ [localizedErrors.required]: "gender_not_selected" }],
  firstName: [
    { [localizedErrors.required]: "first_name_missing" },
    { [localizedErrors.firstNameRequired]: "first_name_missing" },
    {
      [localizedErrors.mustNotContainNumbers]: "first_name_invalid",
    },
  ],
  lastName: [
    { [localizedErrors.required]: "last_name_missing" },
    { [localizedErrors.lastNameRequired]: "last_name_missing" },
    {
      [localizedErrors.mustNotContainNumbers]: "last_name_invalid",
    },
  ],
  email: [
    { [localizedErrors.required]: "email_missing" },
    { [localizedErrors.emailRequired]: "email_missing" },
    {
      [localizedErrors.invalidEmail]: "email_invalid",
    },
  ],
  phoneNumber: [
    { [localizedErrors.required]: "phone_number_missing" },
    {
      [localizedErrors.phoneNumberRequired]: "phone_number_missing",
    },
    {
      [localizedErrors.invalidPhoneNumber]: "phone_number_invalid",
    },
  ],
  street: [
    { [localizedErrors.required]: "street_and_building_missing" },
    {
      [localizedErrors.streetAndBuildingRequired]:
        "street_and_building_missing",
    },
    {
      [localizedErrors.mustContainNumber]: "street_and_building_invalid",
    },
  ],
  postalCode: [
    { [localizedErrors.required]: "postal_code_missing" },
    { [localizedErrors.postalCodeRequired]: "postal_code_missing" },
    {
      [i18next.t("Generic.errors.requiredLength", {
        value: 4,
      })]: "postal_code_invalid",
    },
    {
      [i18next.t("Generic.errors.requiredLength", {
        value: 5,
      })]: "postal_code_invalid",
    },
  ],
  city: [
    { [localizedErrors.required]: "city_missing" },
    { [localizedErrors.cityRequired]: "city_missing" },
    {
      [localizedErrors.mustNotContainNumbers]: "city_invalid",
    },
  ],
  country: [
    { [localizedErrors.required]: "country_not_selected" },
    { [localizedErrors.countryRequired]: "country_not_selected" },
  ],
  birthDateDay: [
    { [localizedErrors.required]: "birth_date_day_missing" },
    {
      [localizedErrors.birthDateRequired]: "birth_date_day_missing",
    },
    {
      [localizedErrors.onlyNumbersAllowed]: "birth_date_day_non_numeric",
    },
    {
      [localizedErrors.invalidDate]: "birth_date_day_invalid",
    },
  ],
  birthDateMonth: [{ [localizedErrors.required]: "birth_date_month_missing" }],
  birthDateYear: [
    { [localizedErrors.required]: "birth_date_year_missing" },
    {
      [localizedErrors.onlyNumbersAllowed]: "birth_date_year_non_numeric",
    },
    {
      [localizedErrors.invalidDate]: "birth_date_year_invalid",
    },
  ],
  birthDate: [
    { [localizedErrors.invalidDate]: "birth_date_invalid" },
    { [localizedErrors.invalidAge]: "minimum_age_not_met" },
  ],
  agreeTerms: [
    {
      [localizedErrors.mandatoryField]: "no_consent",
      [i18next.t("Start.CreateAccount.mandatoryFieldError")]: "no_consent",
    },
  ],
}

const SERVER_VALIDATION_ERROR_TYPES = {
  email_existing: "email_existing",
  email_invalid: "email_invalid",
  birth_date_invalid: "birth_date_invalid",
  postal_code_not_found: "postal_code_not_found",
  countries_must_match: "countries_must_match",
  server_validation_error: "server_validation_error",
}

const VOUCHER_ERROR_TYPES = {
  voucher_already_applied: "voucher_already_applied",
  voucher_not_active: "voucher_not_active",
  voucher_expired: "voucher_expired",
  voucher_incorrect_plan: "voucher_incorrect_plan",
  voucher_not_found: "voucher_not_found",
}

const findError = (object, target) => {
  if (!object || typeof object !== "object") {
    return null
  }

  if (object.hasOwnProperty(target) && typeof object[target] !== "object") {
    return object
  }

  if (Array.isArray(object)) {
    return object.reduce((accum, curr) => {
      accum = findError(curr, target)
      if (accum) {
        return accum
      }
    }, null)
  }

  return Object.keys(object).reduce((accum, curr) => {
    if (typeof object[curr] === "object") {
      accum = findError(object[curr], target)
    }
    if (accum !== null && typeof accum !== "undefined") {
      return accum
    }
  }, null)
}

const getErrorMessageByKey = (errorKey) =>
  i18next.exists(errorKey) && i18next.t(errorKey)

const getErrorMessage = (error) => {
  const unknownErrorMessage = "Unknown error"

  if (!error) {
    return unknownErrorMessage
  }

  if (typeof error === "object") {
    error = findError(error, "message") || {}
  }

  return (
    getErrorMessageByKey(error.id) ||
    ERRORS_MESSAGES[error.message] ||
    error.message ||
    unknownErrorMessage
  )
}

const ButtonAction = ({ element: Element, onClick, ...props }) => {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Element
      {...props}
      onClick={() => {
        setIsLoading(true)
        onClick()
      }}
      isLoading={isLoading}
    />
  )
}

const getVoucherError = (data) => (
  <Trans
    i18nKey={`Voucher.error.${data?.message}`}
    defaults={i18next.t("Voucher.error.DEFAULT")}
    values={data}
    components={{
      action:
        data?.action && data?.components.action ? (
          <ButtonAction
            element={data.components.action}
            onClick={data.action}
            variant="link"
            size="xsm"
            noPadding
          />
        ) : (
          <span />
        ),
    }}
  />
)

const getVoucherErrorType = (error) => {
  switch (error) {
    case "VOUCHER_ALREADY_USED":
      return VOUCHER_ERROR_TYPES.voucher_already_applied
    case "VOUCHER_INACTIVE":
    case "VOUCHER_IS_NOT_ACTIVE":
      return VOUCHER_ERROR_TYPES.voucher_not_active
    case "VOUCHER_EXPIRED":
      return VOUCHER_ERROR_TYPES.voucher_expired
    case "VOUCHER_PRICE_TAGS_NOT_APPLICABLE":
    case "VOUCHER_LOCALES_NOT_APPLICABLE":
    case "VOUCHER_TARGET_GROUP_NOT_APPLICABLE":
    case "VOUCHER_NOT_AUTOREFILL":
      return VOUCHER_ERROR_TYPES.voucher_incorrect_plan
    default:
      return VOUCHER_ERROR_TYPES.voucher_not_found
  }
}

const getSignUpError = ({ error, action, cta }) => {
  const errorId = findError(error, "id")?.id
  const isEmailTaken = /(email|username).taken$/g.test(errorId)

  if (isEmailTaken) {
    return (
      <Trans
        i18nKey="Generic.errors.usernameAlreadyTaken"
        components={{
          action:
            action && cta ? (
              <ButtonAction
                element={cta}
                onClick={action}
                variant="link"
                size="xsm"
                noPadding
              />
            ) : null,
        }}
      />
    )
  }

  return getErrorMessage(error)
}

const getClientValidationErrors = (validationErrors) =>
  Object.entries(validationErrors).reduce(
    (accum, [fieldName, errorMessage]) => {
      const error = CLIENT_VALIDATION_ERROR_TYPES[fieldName]?.find(
        (error) => error[errorMessage]
      )

      if (error && error[errorMessage]) {
        accum.push(error[errorMessage])
      }

      return accum
    },
    []
  )

const getClientAddressSaveErrors = (validationErrors) => {
  const {
    billingAddress = {},
    shippingAddress = {},
    ...fields
  } = validationErrors

  return [
    ...getClientValidationErrors({ ...billingAddress, ...fields }).map(
      (error) => `billing_address_${error}`
    ),
    ...getClientValidationErrors(shippingAddress).map(
      (error) => `shipping_address_${error}`
    ),
  ]
}

const getServerValidationErrorTypes = () => SERVER_VALIDATION_ERROR_TYPES

export {
  getErrorMessageByKey,
  getErrorMessage,
  getVoucherError,
  getVoucherErrorType,
  getSignUpError,
  getClientValidationErrors,
  getClientAddressSaveErrors,
  getServerValidationErrorTypes,
}
