const RebrandedTheme = {
  name: "rebranded",
  breakpoints: ["54em", "74em"],
  space: [0, 4, 8, 12, 16, 24, 32, 48, 64, 80, 128, 264],
  sizes: {
    container: 1216,
    element: 1120,
  },
  colors: {
    text: "hsla(0, 0%, 0%, 1)",
    textLight: "hsla(60, 4%, 44%, 1)",
    textDark: "hsla(60, 4%, 44%, 1)",
    background: "hsla(0, 0%, 100%, 1)",
    primary: "hsla(235, 100%, 60%, 1)",
    primaryLight: "hsla(235, 100%, 64%, 1)",
    primaryDark: "hsla(235, 67%, 48%, 1)",
    primaryUltraLight: "hsla(235, 67%, 48%, 1)",
    secondary: "hsla(60, 100%, 50%, 1)",
    secondaryDark: "hsla(60, 100%, 48%, 1)",
    secondaryLight: "hsla(60, 100%, 75%, 1)",
    secondaryUltraLight: "hsla(60, 100%, 90%, 1)",
    attention: "hsla(6, 85%, 42%, 1)",
    notification: "hsla(60, 100%, 50%, 1)",
    disabled: "hsla(60, 4%, 44%, 1)",
    grey: "hsla(60, 17%, 82%, 1)",
    lightGray: "hsla(60, 20%, 95%, 1)",
    lightRose: "hsla(235, 100%, 98%, 1)",
    lightYellow: "hsla(60, 20%, 95%, 1)",
    lightGreen: "hsla(235, 100%, 98%, 1)",
    lightTurquoise: "hsla(235, 100%, 98%, 1)",
    blue: "hsla(235, 100%, 98%, 1)",
    lightBlue: "hsla(235, 100%, 98%, 1)",
    darkBlue: "hsla(235, 67%, 48%, 1)",
    lightDark: "hsla(0, 5%, 23%, 1)",
    goldDark: "hsla(42, 94%, 53%, 1)",
    trustpilotGreen: "hsla(160, 100%, 36%, 1)",
    rose: "hsla(337, 43%, 75%, 1)",
    darkRose: "hsla(344, 59%, 87%, 1)",
    rawUmber: "hsla(29, 18%, 39%, 1)",
    grullo: "hsla(29, 26%, 58%, 1)",
    tan: "hsla(30, 45%, 71%, 1)",
    lightOrange: "hsla(30, 75%, 80%, 1)",
    lighterOrange: "hsla(30, 88%, 83%, 1)",
    white: "hsla(0, 0%, 100%, 1)",
    black: "hsla(0, 0%, 0%, 1)",
    klarna: "#FFB3C7",
    klarnaLight: "#FFBFD0",
    klarnaDark: "#F0A5b7",
  },
  fonts: {
    body: "'NeueHaasGroteskText Pro', system-ui, sans-serif",
    monospace: "'NeueHaasGroteskText Pro', system-ui, sans-serif",
  },
  fontSizes: [12, 14, 16, 18, 20, 24, 32, 48],
  fontWeights: {
    body: 400,
    heading: 400,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    smallerHeading: 1.33,
    heading: 1.125,
  },
  letterSpacings: {
    body: "normal",
    caps: "0.2em",
  },
  borders: {
    "1px": "1px solid",
  },
  text: {
    fontWeight: "bold",
    caps: {
      textTransform: "uppercase",
      letterSpacing: "caps",
    },
  },
  layout: {
    sectionPadding: {
      py: [9, 10],
    },
    sectionPaddingSmall: {
      py: [6, 10],
    },
    sectionMargin: {
      my: [9, 10],
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      fontSize: 2,
      lineHeight: "body",
    },
  },
  vwo: {
    colors: {
      textLight: {
        color: "textLight",
      },
    },
    page: {
      fontFamily: "body",
      color: "text",
    },
    package: {
      selected: {
        borderColor: "primary",
      },
    },
    heading: {
      variant: "text.heading",
    },
    h1: {
      fontSize: "16px",
      lineHeight: "24px",
    },
    body: {
      fontSize: "14px",
      lineHeight: "20px",
    },
    form: {
      input: {
        borderRadius: 0,
        "&:hover, &:focus": {
          borderColor: "primary",
        },
        "&:focus": {
          borderWidth: "2px",
        },
      },
      phone: {
        borderRadius: 0,
        "&:focus-within": {
          borderWidth: "2px",
        },
      },
      label: {
        textTransform: "none",
        letterSpacing: "normal",
      },
    },
    button: {
      primary: {
        textTransform: "none",
        letterSpacing: "normal",
        lineHeight: "18px",
        "&:disabled": {
          color: "textLight",
          backgroundColor: "#F5F5F0",
          borderColor: "grey",
        },
      },
      link: {
        fontSize: "inherit",
        textTransform: "none",
        letterSpacing: "normal",
      },
    },
    radio: {
      color: "hsla(60, 16%, 82%, 1)",
    },
    carousel: {
      hero: {
        backgroundColor: "#F5F6FF",
        boxShadow: "0px -8px 30px 0px rgba(50, 68, 255, 0.08) inset",
      },
      doctor: {
        backgroundColor: "#C5CAFF",
      },
    },
    components: {
      subscription: {
        container: {
          borderRadius: 0,
        },
        badge: {
          borderRadius: 0,
        },
        image: {
          borderRadius: 0,
        },
      },
      productGallery: {
        slide: {
          borderRadius: 0,
        },
      },
      productCallout: {
        container: {
          backgroundColor: "lightBlue",
          borderRadius: 0,
        },
        badge: {
          backgroundColor: "primary",
          color: "white",
          borderRadius: 0,
        },
      },
      banners: {
        checkIcon: {
          color: "primary",
        },
      },
    },
  },
}

export default RebrandedTheme
