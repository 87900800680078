// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-data-protection-js": () => import("./../../../src/pages/data-protection.js" /* webpackChunkName: "component---src-pages-data-protection-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-impressions-js": () => import("./../../../src/pages/impressions.js" /* webpackChunkName: "component---src-pages-impressions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ingredients-js": () => import("./../../../src/pages/ingredients.js" /* webpackChunkName: "component---src-pages-ingredients-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-our-doctors-js": () => import("./../../../src/pages/our-doctors.js" /* webpackChunkName: "component---src-pages-our-doctors-js" */),
  "component---src-pages-our-products-js": () => import("./../../../src/pages/our-products.js" /* webpackChunkName: "component---src-pages-our-products-js" */),
  "component---src-pages-rate-us-js": () => import("./../../../src/pages/rate-us.js" /* webpackChunkName: "component---src-pages-rate-us-js" */),
  "component---src-pages-referrals-js": () => import("./../../../src/pages/referrals.js" /* webpackChunkName: "component---src-pages-referrals-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-skin-guide-js": () => import("./../../../src/pages/skin-guide.js" /* webpackChunkName: "component---src-pages-skin-guide-js" */),
  "component---src-pages-table-of-contents-js": () => import("./../../../src/pages/table-of-contents.js" /* webpackChunkName: "component---src-pages-table-of-contents-js" */),
  "component---src-pages-telemedicine-consent-js": () => import("./../../../src/pages/telemedicine-consent.js" /* webpackChunkName: "component---src-pages-telemedicine-consent-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-tofu-otp-email-verify-js": () => import("./../../../src/pages/tofu/otp/email/verify.js" /* webpackChunkName: "component---src-pages-tofu-otp-email-verify-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog-category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-subcategory-js": () => import("./../../../src/templates/blog-subcategory.js" /* webpackChunkName: "component---src-templates-blog-subcategory-js" */),
  "component---src-templates-doctor-js": () => import("./../../../src/templates/doctor.js" /* webpackChunkName: "component---src-templates-doctor-js" */),
  "component---src-templates-landing-js": () => import("./../../../src/templates/landing.js" /* webpackChunkName: "component---src-templates-landing-js" */)
}

