import * as storageService from "./storage"

const minutesToExpire =
  parseInt(process.env.GATSBY_REPURCHASE_DIALOG_EXPIRATION_HOURS) || 24

const storageKeys = storageService.getStorageKeys()
const storageKey = storageKeys.REPURCHASE_DIALOG_HIDDEN

const hideRepurchaseDialog = () => {
  storageService.setItem({ id: storageKey, data: new Date() })
}

const unhideRepurchaseDialog = () => {
  storageService.removeItem({ id: storageKey })
}

const getTimeToExpire = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return 0
  }

  return (endDate - startDate) / 1000 / 60
}

const isRepurchaseDialogHidden = () => {
  try {
    const timestamp = storageService.getItem({ id: storageKey })
    if (!timestamp) {
      return false
    }

    const minutesBetween = getTimeToExpire(new Date(timestamp), new Date())

    if (minutesToExpire < minutesBetween) {
      unhideRepurchaseDialog()
      return false
    }

    return true
  } catch {
    return false
  }
}

export {
  hideRepurchaseDialog,
  unhideRepurchaseDialog,
  isRepurchaseDialogHidden,
}
