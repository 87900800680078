import i18next from "i18next"
import ICU from "i18next-icu"
import { initReactI18next } from "react-i18next"

import { i18nLanguage } from "@trueskin-web/locales"

import { dePortalRoutes, dePortalTranslations, deSiteRoutes } from "./de"
import { enPortalRoutes, enPortalTranslations, enSiteRoutes } from "./en"

const fallbackLocale = "en-GB"

const en = {
  translation: {
    ...enPortalTranslations,
    ...enPortalRoutes,
    ...enSiteRoutes,
  },
}

const de = {
  translation: {
    ...dePortalTranslations,
    ...dePortalRoutes,
    ...deSiteRoutes,
  },
}

i18next
  .use(initReactI18next)
  .use(ICU)
  .init({
    resources: { en, de },
    lng: i18nLanguage(),
    fallbackLng: i18nLanguage({ locale: fallbackLocale }),
  })

export default i18next
