import { i18nCurrency, i18nLocale } from "@trueskin-web/locales"

export const formatDate = (date, options = undefined) =>
  new Intl.DateTimeFormat(i18nLocale(), options).format(new Date(date))

export const formatNumber = (value) =>
  new Intl.NumberFormat(i18nLocale(), {
    maximumFractionDigits: 10,
  }).format(value)

export const formatCurrency = (value, currency, minimumFractionDigits = 0) => {
  const locale = i18nLocale({ currency })

  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: i18nCurrency({ locale }),
    minimumFractionDigits,
  }).format(value)
}

export const formatCurrencySymbol = (currency) =>
  new Intl.NumberFormat(i18nLocale({ currency }), {
    style: "currency",
    currency,
  })
    .formatToParts(0)
    .filter(({ type }) => type === "currency")
    .reduce((_acc, { value }) => value, "")

export const formatDateISO = (date) =>
  date && new Date(date).toISOString().split("T")[0]

export const mapFormelOnlyDowngradePreset = (
  presets = [],
  mapPresetResources
) => {
  const downgradePreset = presets.find(
    ({ occurrence, isRecommended, isSelected }) =>
      occurrence !== "occ_once" && !isRecommended && !isSelected
  )

  if (!downgradePreset) {
    return
  }

  const {
    id,
    details: { title },
    computedMetadata: { mainLabel: price },
    summary: { items: presetItems },
  } = downgradePreset

  const products = mapPresetResources(presetItems)

  return {
    id,
    title,
    price,
    products,
  }
}
