const vwoStorageKeyPrefix = "__vwo__"

const storageKeys = {
  APPLIED_VOUCHER: "voucher_applied",
  AUTH_JWT: "__formel_token__",
  BANNER_DISMISSED: "cms_banner-dismissed",
  BANNERS_HIDDEN: "hiddenBanners",
  DISABLE_INITIAL_TREATMENT_PLAN_REDIRECT:
    "DISABLE_INITIAL_TREATMENT_PLAN_REDIRECT",
  FIRSTNAME: "firstName",
  HOME_INTRO_DISMISSED: "home.intro-dismissed",
  INITIAL_ANSWERS: "initialQAnswers",
  INITIAL_QUESTIONS: "initialQQuestions",
  INITIAL_Q_TREATMENT_GOAL_KEY: "initial_q_treatment_goal",
  NPS_DISMISSED: "dontShowNps",
  POSTHOG_USER_IDENTIFIED: "posthogUserIdentified",
  REPURCHASE_DIALOG_HIDDEN: "repurchase-dialog-hidden",
  USER_DETAILS: "userDetails",
  TRACKING: "__formel_tracking__",
  TRACKING_ALL: "__formel_tracking_all__",
  USER_CREATED_AT: "user_created_at",
  VOUCHER_URL: "voucher_code",
  TEMP_TOKEN: "tempToken",
  VWO_CLOSED_CLA: `${vwoStorageKeyPrefix}closed_cla`,
  VWO_INITIAL_QUESTIONNARE_ID: "INITIAL_QUESTIONNARE_ID",
  VWO_TOFU_INITIAL_QUESTIONNARE_ID: `${vwoStorageKeyPrefix}tofuInitialQuestionnareId`,
  VWO_INITIAL_QUESTIONNARE_PROVIDER: "INITIAL_QUESTIONNARE_PROVIDER",
  VWO_QUESTIONNARE_PROVIDER: "QUESTIONNARE_PROVIDER",
  VWO_PLAN_SELECTION_TEST_RELEASE: `${vwoStorageKeyPrefix}planSelectionTestRelease`,
  VWO_SIDE_EFFECTS_SELF_SERVICE: `${vwoStorageKeyPrefix}side_effects_self_service`,
  VWO_TEST_PRESET_TYPE: `${vwoStorageKeyPrefix}testPresetType`,
  VWO_THEME_VARIANT: `${vwoStorageKeyPrefix}themeVariant`,
  VWO_TREATMENT_INDICATION: "TREATMENT_INDICATION",
  VWO_TREATMENT_INGREDIENTS: "TREATMENT_INGREDIENTS",
  VWO_FORMULA_SEGMENTS: "FORMULA_SEGMENTS",
  TOFU_JWT: "__tofu__jwt",
  TOFU_PAYMENT_GATEWAY: "__tofu__paymentGateway",
  TOFU_PHOTOS_UPLOAD_SUCCESS: "TOFU_PHOTOS_UPLOAD_SUCCESS",
  VWO_SUBSCRIPTION_TYPE: "SUBSCRIPTION_TYPE",
  VWO_FORMULA_EXPLORATION: `${vwoStorageKeyPrefix}formula_exploration`,
}

// TODO: [FS-6060] add cookie support
const storageTypes = {
  LOCAL: "localStorage",
  SESSION: "sessionStorage",
}

const clientOnly = (action) =>
  typeof window === "undefined" ? () => {} : action

const getStorageKeys = () => storageKeys

const getStorageTypes = () => storageTypes

const getItem = clientOnly(({ id, storageType = storageTypes.LOCAL }) => {
  const data =
    storageType instanceof Array
      ? window[
          storageType.find((storage) => window[storage].getItem(id))
        ]?.getItem(id)
      : window[storageType].getItem(id)

  try {
    return JSON.parse(data)
  } catch {
    return data
  }
})

const setItem = clientOnly(
  ({ id, data: payload, storageType = storageTypes.LOCAL }) => {
    const data = typeof payload === "string" ? payload : JSON.stringify(payload)

    storageType instanceof Array
      ? storageType.forEach((storage) => window[storage].setItem(id, data))
      : window[storageType].setItem(id, data)
  }
)

const removeItem = clientOnly(({ id, storageType = storageTypes.LOCAL }) => {
  storageType instanceof Array
    ? storageType.forEach((storage) => window[storage].removeItem(id))
    : window[storageType].removeItem(id)
})

const removeAll = clientOnly(({ storageType = storageTypes.LOCAL }) => {
  Object.values(storageKeys).forEach((id) => removeItem({ id, storageType }))
})

export {
  getStorageTypes,
  getStorageKeys,
  getItem,
  setItem,
  removeItem,
  removeAll,
  vwoStorageKeyPrefix,
}
