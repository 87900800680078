const siteId = process.env.GATSBY_CUSTOMERIO_SITE_ID
const apiKey = process.env.GATSBY_CUSTOMERIO_API_KEY
const apiUrl = process.env.GATSBY_CUSTOMERIO_API_URL

const customerioClient = async (path, { body, ...config }) => {
  if (!siteId || !apiKey || !apiUrl) {
    return
  }

  fetch(apiUrl + "/" + path, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Basic ${btoa(siteId + ":" + apiKey)}`,
    },
    ...config,
    body: JSON.stringify(body),
  })
    .then(async (response) => {
      if (!response.ok) {
        if (response.status === 401) {
          throw Error("Unauthorized request")
        } else {
          throw Error(response.statusText)
        }
      }
      return response.json()
    })
    .catch((error) => {
      throw error
    })
}

const subscribe = ({ email, first_name }) =>
  customerioClient(email, {
    method: "PUT",
    body: {
      email,
      first_name,
    },
  })

const track = ({ customerId, eventName = "widget_signup", source }) =>
  customerioClient(customerId + "/events", {
    body: {
      name: eventName,
      data: { source },
    },
  })

export { subscribe, track }
