import * as storageService from "./storage"

const storageKeys = storageService.getStorageKeys()
const storageKey = storageKeys.VOUCHER_URL

const getVoucher = ({ storageType } = {}) =>
  storageService.getItem({ id: storageKey, storageType })

const saveVoucher = ({ data, storageType } = {}) => {
  storageService.setItem({ id: storageKey, data, storageType })
}

const removeVoucher = ({ storageType } = {}) => {
  storageService.removeItem({
    id: storageKey,
    storageType,
  })
}

const getAppliedVoucher = () =>
  storageService.getItem({
    id: storageKeys.APPLIED_VOUCHER,
  })

const saveAppliedVoucher = (data) => {
  storageService.setItem({
    id: storageKeys.APPLIED_VOUCHER,
    data,
  })
}

const removeAppliedVoucher = () =>
  storageService.removeItem({
    id: storageKeys.APPLIED_VOUCHER,
  })

export {
  getVoucher,
  saveVoucher,
  removeVoucher,
  saveAppliedVoucher,
  getAppliedVoucher,
  removeAppliedVoucher,
}
