import { gql } from "@apollo/client"

const importTofuUser = gql`
  mutation ($token: String!) {
    importTofuUser(token: $token) {
      jwt
      user
    }
  }
`

export { importTofuUser }
