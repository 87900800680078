const I18N_LOCALE_COOKIE_KEY = "_formel_locale"

const getDaysInMs = (days = 30) => days * 24 * 60 * 60 * 1000

const parseCookie = (str) =>
  str
    ? str
        .split(";")
        .map((v) => v.split("="))
        .reduce((acc, v) => {
          acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim())
          return acc
        }, {})
    : {}

function getCookie(cname) {
  if (typeof window === "undefined") {
    return ""
  }

  const name = `${cname}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(";")
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}

function setCookie(name, value, days = 30) {
  if (typeof window === "undefined") {
    return
  }

  const d = new Date()
  d.setTime(d.getTime() + getDaysInMs(days))
  document.cookie = `${name}=${value};path=/;expires=${d.toGMTString()};secure`
}

function removeCookie(name) {
  setCookie(name, "", -1)
}

function getLocaleCookie() {
  return getCookie(I18N_LOCALE_COOKIE_KEY)
}

function setLocaleCookie(locale) {
  setCookie(I18N_LOCALE_COOKIE_KEY, locale)
}

export {
  parseCookie,
  getCookie,
  setCookie,
  removeCookie,
  getLocaleCookie,
  setLocaleCookie,
}
