import { apiV1 } from "./api"

function subscribe(email) {
  return apiV1("newsletter/subscribe", {
    body: {
      email,
    },
  })
}

function confirmSubscription(token) {
  return apiV1("newsletter/confirm-subscription", {
    body: {
      token,
    },
  })
}

export { subscribe, confirmSubscription }
