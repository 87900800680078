import * as storageService from "./storage"

const storageKeys = storageService.getStorageKeys()
const storageKey = storageKeys.POSTHOG_USER_IDENTIFIED

const storageTypes = storageService.getStorageTypes()

const isUserIdentified = () =>
  !!storageService.getItem({
    id: storageKey,
    storageType: storageTypes.SESSION,
  })

const identifyUser = () =>
  storageService.setItem({
    id: storageKey,
    data: 1,
    storageType: storageTypes.SESSION,
  })

export { isUserIdentified, identifyUser }
