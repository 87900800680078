import * as storageService from "./storage"

const storageKeys = storageService.getStorageKeys()
const storageKey = storageKeys.TRACKING
const storageKeyAll = storageKeys.TRACKING_ALL

const getTrackingTouchpoints = () =>
  storageService.getItem({ id: storageKey }) || null

const getAllTrackingTouchPoints = () =>
  storageService.getItem({ id: storageKeyAll }) || []

const addNewTouchPoint = (touchPoint) => {
  storageService.setItem({
    id: storageKeyAll,
    data: [...getAllTrackingTouchPoints(), { ...touchPoint, date: new Date() }],
  })
}

const saveTrackingTouchpoints = (touchpoints) => {
  storageService.setItem({ id: storageKey, data: touchpoints })
}

const clearTrackingTouchpoints = () => {
  storageService.removeItem({ id: storageKey })
  storageService.removeItem({ id: storageKeyAll })
}

export {
  getTrackingTouchpoints,
  getAllTrackingTouchPoints,
  addNewTouchPoint,
  saveTrackingTouchpoints,
  clearTrackingTouchpoints,
}
