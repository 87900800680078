import * as storageService from "./storage"

const storageKeys = storageService.getStorageKeys()
const storageKey = storageKeys.NPS_DISMISSED

const storageTypes = storageService.getStorageTypes()

const isNpsDismissed = () =>
  !!storageService.getItem({
    id: storageKey,
    storageType: storageTypes.SESSION,
  })

const dismissNps = () =>
  storageService.setItem({
    id: storageKey,
    data: true,
    storageType: storageTypes.SESSION,
  })

export { isNpsDismissed, dismissNps }
