import { getCookie } from "@trueskin-web/cookies"
import { sleep } from "@trueskin-web/functions"
import { i18nLocale } from "@trueskin-web/locales"
import { authService, storageService } from "@trueskin-web/services"

import { logout } from "./auth"
import { getConsent, trackEvent } from "./marketing"

const storageTofuJwtKey = storageService.getStorageKeys().TOFU_JWT
const setHeader = (headers, headerKey, headerValue) => {
  if (!headers || !headerKey || !headerValue) {
    return
  }

  if (headerKey === "Authorization") {
    headers[headerKey] = `Bearer ${headerValue}`

    return
  }

  headers[headerKey] = headerValue
}

async function onErrorInterceptor(response) {
  try {
    const traceId = response.headers.get("x-fs-trace-id")

    const data = await response.json()

    if (response.status >= 500) {
      return { ...data, traceId }
    }

    // v1 has classic data: { data, error, message, statusCode }
    // v2 has custom error on data: { data, error }
    // TODO: update when v2 error mapping changes and remove these comments
    const error = data.statusCode ? data : data.error

    return { ...error, traceId }
  } catch {
    throw "Please check your internet connection and try again"
  }
}

async function onSuccessInterceptor(response) {
  if (response.status === 401 || response.status === 403) {
    logout()
    window.location.assign(window.location)

    return
  }

  if (response.status === 204) {
    return ""
  }

  if (response.ok) {
    const data = await response.clone().json()

    if (data.error) {
      throw response
    }

    if (data._event) {
      if (Array.isArray(data._event)) {
        data._event.forEach((event) => trackEvent(event))
      } else {
        trackEvent(data._event)
      }
    }

    return data
  }

  throw response
}

async function onFetchError(response, url, options, retries, interval) {
  if (retries < 1) {
    throw response
  }

  await sleep(interval)
  return doFetch(url, options, retries - 1, interval)
}

async function doFetch(url, options, retries, interval) {
  try {
    let response = await window.fetch(url, options)

    if (response.status >= 500) {
      response = await onFetchError(response, url, options, retries, interval)
    }

    return response
  } catch (err) {
    throw await onFetchError(err, url, options, retries, interval)
  }
}

async function client(
  url,
  endpoint,
  { body, params, ...config } = {},
  retries,
  interval
) {
  let apiUrl = endpoint.startsWith("http") ? endpoint : `${url}/${endpoint}`

  if (params) {
    apiUrl += `?${new URLSearchParams(params)}`
  }

  const headers =
    body instanceof FormData ? {} : { "content-type": "application/json" }

  setHeader(
    headers,
    "Authorization",
    // Short term solution for TOFU web journey experiment
    // TODO If the experiment succeeds, a long term solution will be made
    url === process.env.GATSBY_API_TOFU_URL
      ? storageService.getItem({ id: storageTofuJwtKey })
      : authService.getJwt()
  )

  setHeader(headers, "x-fs-locale", i18nLocale())
  setHeader(headers, "x-current-page", window.location.href)
  setHeader(headers, "x-user-consent-fb", getConsent("Facebook Pixel"))
  setHeader(headers, "x-fbc", getCookie("_fbc"))
  setHeader(headers, "x-fbp", getCookie("_fbp"))
  setHeader(headers, "x-ttclid", getCookie("_ttclid"))

  const apiConfig = {
    method: body ? "POST" : "GET",
    ...config,
    headers: {
      ...headers,
      ...config.headers,
    },
    body:
      body instanceof FormData ? body : body ? JSON.stringify(body) : undefined,
  }

  try {
    const response = await doFetch(apiUrl, apiConfig, retries, interval)

    return await onSuccessInterceptor(response)
  } catch (err) {
    throw await onErrorInterceptor(err)
  }
}

const apiV1 = async (endpoint, request, retries = 0, interval = 500) =>
  client(process.env.GATSBY_API_URL, endpoint, request, retries, interval)

const apiV2 = async (endpoint, request = {}, retries = 0, interval = 500) =>
  client(process.env.GATSBY_API_V2_URL, endpoint, request, retries, interval)

const apiTofu = async (endpoint, request = {}, retries = 0, interval = 500) =>
  client(process.env.GATSBY_API_TOFU_URL, endpoint, request, retries, interval)

export { apiV1, apiV2, apiTofu }
