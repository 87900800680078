import i18next from "./i18next"

const mapRoutes = (currentRoute, parentRoute = "") => {
  const path = currentRoute.path ? `/${currentRoute.path}` : ""
  const url = `${parentRoute}${path}`

  const route = !path ? {} : { path, url }

  const routes = Object.keys(currentRoute)
    .filter((name) => name.charAt(0) === name.charAt(0).toUpperCase())
    .reduce(
      (acc, name) => ({
        ...acc,
        [name]: mapRoutes(currentRoute[name], url),
      }),
      {}
    )

  return {
    ...route,
    ...routes,
  }
}

const Routes = mapRoutes(i18next.t("Routes"))

export { Routes }
