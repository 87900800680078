import {
  Identify,
  getUserId,
  identify,
  init,
  reset,
  setUserId,
  track,
} from "@amplitude/analytics-browser"

const amplitudeApiKey = process.env.GATSBY_AMPLITUDE_API_KEY
const isProduction = process.env.GATSBY_DEPLOY_ENVIRONMENT === "production"

const logLevels = {
  NONE: 0,
  ERROR: 1,
  WARN: 2,
  VERBOSE: 3,
  DEBUG: 4,
}

const ANALYTIC_EVENTS = {
  INITIAL_PICTURE_UPLOAD_PAGE_VIEWED: "initial picture upload page - viewed",
  INITIAL_PICTURES_SUBMITTED: "initial pictures - submitted",
  HOME_PAGE_VIEWED: "home page - viewed",
  HOME_ORDER_STATUS_CLICKED: "home order status - clicked",
  HOME_YOUR_ROUTINE_CLICKED: "home your routine - clicked",
  HOME_YOUR_PLAN_CLICKED: "home your plan - clicked",
  HOME_MESSAGES_CLICKED: "home messages - clicked",
  HOME_YOUR_PROGRESS_CLICKED: "home your progress - clicked",
  HOME_REFER_A_FRIEND_CLICKED: "home refer a friend - clicked",
  TREATMENT_PLAN_PAGE_VIEWED: "treatment plan page - viewed",
  TREATMENT_PLAN_DOCTOR_NOTE_EXPANDED: "treatment plan doctor note - expanded",
  TREATMENT_PLAN_FIRST_FORMEL_TAB_CLICKED:
    "treatment plan first formel tab - clicked",
  TREATMENT_PLAN_SECOND_FORMEL_TAB_CLICKED:
    "treatment plan second formel tab - clicked",
  TREATMENT_PLAN_INGREDIENTS_CLICKED: "treatment plan ingredients - clicked",
  TREATMENT_PLAN_TIPS_CLICKED: "treatment plan tipps - clicked",
  TREATMENT_PLAN_SECOND_ROUTINE_CLICKED:
    "treatment plan second routine - clicked",
  MESSAGES_PAGE_VIEWED: "messages page - viewed",
  MESSAGES_ARTICLE_VIEWED: "messages - article - viewed",
  MESSAGES_CONTENT_PAGE_VIEWED: "messages - content page - viewed",
  MESSAGES_AYD_CLICKED: "messages AYD - clicked",
  MESSAGES_MENU_CLICKED: "messages - menu - clicked",
  MESSAGES_SUB_MENU_CLICKED: "messages - sub-menu - clicked",
  MESSAGES_MEDICAL_QUESTION_SELECTED: "messages medical question - selected",
  MESSAGES_NON_MEDICAL_QUESTION_SELECTED:
    "messages non-medical question - selected",
  MESSAGES_AYD_SENT: "messages AYD - sent",
  MESSAGES_AYD_RATING_SUBMITTED: "messages - AYD rating - submitted",
  MESSAGES_ARTICLE_RATING_SUBMITTED: "messages - article rating - submitted",
  MESSAGES_CONTENT_PAGE_RATING_SUBMITTED:
    "messages - content rating - submitted",
  MESSAGES_MEDICAL_FAQ_CLICKED: "messages - medical FAQ - clicked",
  ROUTINE_PAGE_VIEWED: "routine page - viewed",
  ROUTINE_PAGE_FIRST_FORMEL_CLICKED: "routine page first formel - clicked",
  ROUTINE_PAGE_SECOND_FORMEL_CLICKED: "routine page second formel - clicked",
  NEXT_ORDER_PAGE_VIEWED: "next order page - viewed",
  NEXT_ORDER_SHIFT_RENEWAL_CLICKED: "next order shift renewal - clicked",
  NEXT_ORDER_SHIFT_RENEWAL_SAVED: "next order shift renewal - saved",
  NEXT_ORDER_SET_CUSTOMISATION_CLICKED:
    "next order set customisation - clicked",
  NEXT_ORDER_SET_CUSTOMISATION_SAVED: "next order set customisation - saved",
  NEXT_ORDER_SET_SIZE_CLICKED: "next order set size - clicked",
  NEXT_ORDER_SET_SIZE_CONFIRMED: "next order set size - confirmed",
  NEXT_ORDER_SPF_CLICKED: "next order SPF - clicked",
  NEXT_ORDER_SPF_CONFIRMED: "next order SPF - confirmed",
  NEXT_ORDER_SHIFT_RENEWAL_BOTTOM_CLICKED:
    "next order shift renewal bottom - clicked",
  NEXT_ORDER_CANCELLATION_CLICKED: "next order cancellation - clicked",
  TREATMENT_PROGRESS_PAGE_VIEWED: "progress page - viewed",
  TREATMENT_PROGRESS_FRONT_IMAGE_CLICKED: "progress view front - clicked",
  TREATMENT_PROGRESS_RIGHT_IMAGE_CLICKED: "progress view right - clicked",
  TREATMENT_PROGRESS_LEFT_IMAGE_CLICKED: "progress view left - clicked",
  TREATMENT_PROGRESS_UPLOAD_PHOTOS_CLICKED:
    "progress pictures uploading page - clicked",
  TREATMENT_PROGRESS_PHOTOS_UPLOADED: "progress pictures - uploaded",
  TREATMENT_PROGRESS_PHOTOS_EXPANSION_CLICKED:
    "progress pictures expansion - clicked",
  NPS_OVERLAY_VIEWED: "nps overlay - viewed",
  NPS_OVERLAY_CLOSED: "nps overlay - closed",
  NPS_SUBMITTED: "nps - submitted",
  NPS_REASON_SUBMITTED: "nps reason - submitted",
  CHECK_IN_QUESTIONNAIRE_PAGE_VIEWED: "check-in questionnaire page - viewed",
  CHECK_IN_QUESTIONNAIRE_SUBMITTED: "check-in questionnaire - submitted",
  CHECK_IN_QUESTIONNAIRE_CLOSED: "check-in questionnaire - closed",
  CHECK_IN_PICTURES_UPLOAD_PAGE_VIEWED: "check-in picture upload page - viewed",
  CHECK_IN_PICTURES_SUBMITTED: "check-in pictures - submitted",
  CHECK_IN_PICTURES_SKIPPED: "check-in pictures - skipped",
  CHECK_IN_PICTURES_CLOSED: "check-in pictures - closed",
  ORDER_HISTORY_PAGE_VIEWED: "order history page - viewed",
  MID_2M_QUESTIONNAIRE_PAGE_VIEWED: "Mid-2M-update questionnaire page - viewed",
  MID_2M_QUESTIONNAIRE_SUBMITTED: "Mid-2M-update questionnaire - submitted",
  MID_2M_QUESTIONNAIRE_CLOSED: "Mid-2M-update questionnaire - closed",
  MID_2M_PICTURE_UPLOAD_PAGE_VIEWED:
    "Mid-2M-update picture upload page - viewed",
  MID_2M_PICTURES_SUBMITTED: "Mid-2M-update pictures - submitted",
  MID_2M_PICTURES_SKIPPED: "Mid-2M-update pictures - skipped",
  MID_2M_PICTURES_CLOSED: "Mid-2M-update pictures - closed",
  CANCELLATION_POPUP_VIEWED: "cancellation pop-up - viewed",
  CANCELLATION_POPUP_PROCESSED: "cancellation pop-up - cancellation confirmed",
  CANCELLATION_POPUP_CONFIRMED: "cancellation pop-up - user confirmed",
  CANCELLATION_POPUP_DEFLECTED: "cancellation pop-up - cancellation deflected",
  CANCELLATION_POPUP_CLOSED: "cancellation pop-up - closed",
  CANCELATION_QUESTIONNAIRE_VIEWED: "cancellation questionnaire - viewed",
  CANCELATION_QUESTIONNAIRE_CONFIRMED: "cancellation questionnaire - confirmed",
  CANCELATION_REACTIVATE_ACCOUNT: "cancelation - reactivate account",
  ONBOARDING_CANCELATION_FLOW_GET_HELP:
    "onboarding - cancelation flow - get help",
  ONBOARDING_WHAT_HAPPENS_NEXT_VIEWED:
    "onboarding - what happens next - viewed",
  ONBOARDING_CAROUSEL_VIEWED: "onboarding - carousel - viewed",
  REMINDER_POPUP_VIEWED: "reminder pop-up - viewed",
  REMINDER_POPUP_USER_CONFIRMED: "reminder pop-up - user confirmed",
  REMINDER_POPUP_CONFIRMED: "reminder pop-up - reminder confirmed",
  TREATMENT_PLAN_EXPLORATION_CLICKED:
    "treatment plan formula discover more - clicked",
  TREATMENT_PLAN_EXPLORATION_PAGE_VIEWED: "formula page - viewed",
  TREATMENT_PLAN_EXPLORATION_SECTION_EXPANDED:
    "formula page - section expanded",
  TREATMENT_PLAN_EXPLORATION_RATING_SUBMITTED:
    "formula page - rating submitted",
}

const ANALYTIC_EVENT_PROPS = {
  PAGE: "Page",
  TOPIC: "Topic",
  TYPE: "Type",
  ACTION: "Action",
  NUMBER_OF_PICTURES_SENT: "# of pictures sent",
  OLD_RENEWAL_DATE: "Old renewal date",
  NEW_RENEWAL_DATE: "New renewal date",
  REASON: "Reason",
  NPS_VALUE: "NPS-value",
  AB_TEST_VARIANT: "A/B test variant",
  CATEGORY: "Category",
  SUB_CATEGORY: "Sub-category",
  RATING: "Rating",
  QUESTION: "Question",
  SECTION: "Section",
}

const isBrowser = () => typeof window !== "undefined"

const logErrorMessage = (error) =>
  console.error("Logging analytics event error:", error)

if (isBrowser() && amplitudeApiKey) {
  try {
    init(amplitudeApiKey, null, {
      logLevel: isProduction ? logLevels.WARN : logLevels.VERBOSE,
    })
  } catch (error) {
    logErrorMessage(error)
  }
}

const trackEvent = (eventName, data) => {
  try {
    track(eventName, data)
  } catch (error) {
    logErrorMessage(error)
  }
}

const identifyUser = (id) => {
  try {
    setUserId(id)
  } catch (error) {
    logErrorMessage(error)
  }
}

const setUserProperties = (props) => {
  try {
    const propertiesKeys = Object.keys(props)

    propertiesKeys.forEach((key) => {
      const event = new Identify()
      event.set(key, props[key])

      identify(event)
    })
  } catch (error) {
    logErrorMessage(error)
  }
}

const isUserIdentified = () => {
  try {
    return getUserId()
  } catch (error) {
    logErrorMessage(error)
  }
}

const removeIdentifier = () => {
  try {
    reset()
  } catch (error) {
    logErrorMessage(error)
  }
}

export {
  ANALYTIC_EVENTS,
  ANALYTIC_EVENT_PROPS,
  trackEvent,
  identifyUser,
  setUserProperties,
  removeIdentifier,
  isUserIdentified,
}
