import { gql } from "@apollo/client"

const getVoucher = gql`
  query ($voucher: String!) {
    getVoucher(code: $voucher) {
      confirmationMessage
    }
  }
`

export { getVoucher }
