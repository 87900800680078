/** @jsx jsx */
import { format } from "date-fns"
import { createContext, useContext, useEffect, useMemo, useState } from "react"

import {
  amplitudeClient,
  authClient,
  marketingClient,
  useMutation,
  useQuery,
  useQueryClient,
  userClient,
} from "@trueskin-web/apis"
import { tofuGqlClient, useGqlMutation } from "@trueskin-web/gql"
import { authService, posthogService, vwoService } from "@trueskin-web/services"
import { jsx } from "@trueskin-web/theme"

const AuthContext = createContext()

function AuthProvider(props) {
  const queryClient = useQueryClient()

  const {
    status,
    isLoading,
    isError,
    data: user,
    error,
  } = useQuery("currentUser", authClient.getCurrentUser)

  const { isLoading: isUserIdentityLoading, data: userIdentity } = useQuery(
    "userIdentity",
    authClient.getIdentity
  )

  const extractIdentity = ({ id, firstName, lastName }) => ({
    id,
    firstName,
    lastName,
  })

  const { mutateAsync: login } = useMutation(authClient.login, {
    onSuccess: (data) => {
      queryClient.setQueryData("currentUser", data)
      queryClient.setQueryData("userIdentity", extractIdentity(data))
      userClient.saveVwoExperiments(vwoService.getVwoExperiments())
    },
  })

  const { mutateAsync: emailLogin } = useMutation(authClient.emailLogin, {
    onSuccess: (data) => {
      queryClient.setQueryData("currentUser", data)
      queryClient.setQueryData("userIdentity", extractIdentity(data))
      userClient.saveVwoExperiments(vwoService.getVwoExperiments())
    },
  })

  const [tofuSync] = useGqlMutation(tofuGqlClient.importTofuUser, {
    onCompleted: ({ importTofuUser: { jwt, user } }) => {
      authService.saveJwt(jwt)

      queryClient.setQueryData("currentUser", user)
      queryClient.setQueryData("userIdentity", extractIdentity(user))
      userClient.saveVwoExperiments(vwoService.getVwoExperiments())
    },
  })

  const { mutateAsync: logout } = useMutation(authClient.logout, {
    onSuccess: () => {
      amplitudeClient.removeIdentifier()
      queryClient.clear()
    },
  })

  const { mutateAsync: register } = useMutation(authClient.register, {
    onSuccess: async (data) => {
      queryClient.setQueryData("userIdentity", extractIdentity(data))
      userClient.saveVwoExperiments(vwoService.getVwoExperiments())
      await queryClient.refetchQueries("currentUser")
    },
  })

  const confirmEmail = useMutation(authClient.confirmEmail, {
    onSuccess: async ({ jwt, user }) => {
      authService.saveJwt(jwt)
      queryClient.setQueryData("userIdentity", extractIdentity(user))
      await queryClient.refetchQueries("currentUser")
    },
  })

  const [renewalDateDialog, setRenewalDateDialog] = useState(false)

  useEffect(() => {
    if (userIdentity) {
      authService.saveFirstname(userIdentity.firstName || "Anonymous")
    }
  }, [userIdentity])

  useEffect(() => {
    if (user) {
      authService.saveUserCreationTimestamp(user.createdAt)
      authService.saveUserTreatmentDetails(user)
      authService.saveUserSubscriptionType(user)
    }
  }, [user])

  const value = useMemo(
    () => ({
      status,
      isLoading,
      isError,
      user,
      userIdentity,
      isUserIdentityLoading,
      error,
      login,
      emailLogin,
      tofuSync,
      logout,
      register,
      confirmEmail,
      renewalDateDialog,
      setRenewalDateDialog,
    }),
    [
      status,
      isLoading,
      isError,
      user,
      userIdentity,
      isUserIdentityLoading,
      error,
      login,
      emailLogin,
      tofuSync,
      logout,
      register,
      confirmEmail,
      renewalDateDialog,
      setRenewalDateDialog,
    ]
  )

  return <AuthContext.Provider value={value} {...props} />
}

function trackUser(context) {
  if (context?.user?._id) {
    if (marketingClient.getConsent("Google Analytics")) {
      if (!window.dataLayer) {
        window.dataLayer = []
      }

      const hasAlreadySentUserId = window.dataLayer.some(
        ({ event: storedEvent }) =>
          storedEvent === marketingClient.EVENT_TYPES_ENUM.SET_USER_ID
      )

      if (!hasAlreadySentUserId) {
        marketingClient.trackEvent({
          name: marketingClient.EVENT_TYPES_ENUM.SET_USER_ID,
          custom_data: {
            userId: context.user._id,
            signUpDate: format(new Date(context.user.createdAt), "yyyy-MM-dd"),
            redoInitialQuestionnaire: context.user.redoInitialQuestionnaire,
            treatmentGoal: context.user.treatmentGoals?.join(","),
            ...vwoService.getVwoExperimentsEventData(),
          },
        })
      }
    }

    if (window.posthog) {
      if (!posthogService.isUserIdentified()) {
        window.posthog.identify(
          context?.user?._id,
          {},
          { email: context?.user?.email }
        )

        posthogService.identifyUser()
      }
    }

    if (!amplitudeClient.isUserIdentified()) {
      amplitudeClient.identifyUser(context.user._id)
    }
  }
}

function useAuth() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  trackUser(context)
  return context
}

export { AuthProvider, useAuth }
